function setEqualHeight() {
    $(".grid-news-item .title").matchHeight();
    $(".news-content-wrapper .content").matchHeight();
    $(".news-image-wrapper").matchHeight();
    $(".prva-zemljevid-newsletter .grid-element-content").matchHeight();
    $(".footer-content-cell .grid-element-content").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 110,
        margin_top = 0;
    if (current_breakpoint_num > 3) {
        switch (current_breakpoint) {
            case "xxlarge":
                scroll_limit = 110;
                break;
            case "xlarge":
                scroll_limit = 110;
                break;
            case "large":
                scroll_limit = 88;
                break;
            default:
                scroll_limit = 78;
        }
        if ($(window).scrollTop() < 2) {
            margin_top = "";
        } else if ($(window).scrollTop() <= scroll_limit) {
            margin_top = - $(window).scrollTop() + "px";
        } else {
            margin_top = - scroll_limit + "px";
        }
        $("#header-wrapper").css("margin-top", margin_top);
    }
});

optikaSimenc = function($) {
    var procPath    = arsluna.options.subdir + "content/ecommerce/",
        formId      = 'vision_exam_order',
        form,
        submitInProgress = false;

    "use strict";

    function reserveDate() {
        var productCalendarPk = form.find('[name="product_calendar_pk"]').val();
        $.ajax({
            async   : true,
            type    : "POST",
            url     : procPath + "arsluna.ecommerce.post.php",
            data    : {
                "p_request"           : "reserve_date",
                "product_calendar_pk" : productCalendarPk,
                "p_timestamp"         : new Date().getTime()
            }
        }).done(function(result) {
            if (result.status === 0) {
                if (result.data.available <= 0) {
                    $('[name="time"][value="' + productCalendarPk + '"]').parent().parent().parent().hide(500, function(){
                        $('[name="time"][value="' + productCalendarPk + '"]').parent().parent().parent().remove();
                    });
                }
            }
        }).fail(function(e){
            return false;
        });
    } // END reserveDate

    function submitReservation() {
        var data,
            text = [];
        form = $('#' + formId);
        if (submitInProgress) {
            return false;
        }
        submitInProgress = true;
        data = form.serializeArray();
        form.find(".error").html("").addClass("display-none");
        form.find('input[type="hidden"],input[type="text"], input[type="number"], input[type="mail"], input[type="tel"], input[type="radio"], input[type="checkbox"],  input:checkbox, input:password, input:file, select, textarea').each(function(){
            var inputType = 'text';
            if ($(this).is(':radio')) {
                inputType = 'radio';
            } else if ($(this).is(':checkbox')) {
                inputType = 'checkbox';
            } else if ($(this).is('select')) {
                inputType = 'select';
            }
            var $name = $(this).attr("name"),
                $item = {},
                $row  = {};
                $item ["type"]     = inputType;
                $item ["text"]     = $(this).attr("data-text");
                $item ["message"]  = $(this).attr("data-message");
                $item ["required"] = $(this).prop("required");
                $row[$name]        = $item;
                text.push($row);

        });
        $.ajax({
            async   : true,
            type    : "POST",
            url     : arsluna.options.subdir + 'content/arsluna.content.post.php',
            data    : {
                "p_request"   : "form_builder_form_submit",
                "data"        : JSON.stringify(data),
                "text"        : JSON.stringify(text),
                "p_timestamp" : new Date().getTime()
            }
        }).done(function(result) {
            var offsetTop;
            if (result.status === 0) {
                form.find(".submit-fail").html("").addClass("display-none");
                if (form.find('[name="success_message"]').length > 0) {
                    successMessage = form.find('[name="success_message"]').val();
                }
                alertify
                    .reset()
                    .okBtn(arsluna.msg.label.ok)
                    .alert(
                        result.msg,
                        function(){
                            $.fancybox.close();
                        }
                    );
                form.find('input[type="text"], input[type="number"], input[type="mail"], input[type="tel"],input:password, input:file, textarea').each(function(){
                    if($(this).data("preserve_on_submit") != 1) {
                        $(this).val("");
                        formBuilder_vision_exam_order.togglePlaceholderLabel($(this));
                    }
                });
                reserveDate();
                submitInProgress = false;
            } else {
                submitInProgress = false;
                form.find(".submit-fail").html(result.msg).removeClass("display-none");
                form.find(".submit-success").addClass("display-none");
                $.each(result.error_array, function( index, value ) {
                    $("#" + formId + "-" + index + "-errm").html(value);
                    $("#" + formId + "-" + index + "-errm").removeClass("display-none");
                });
                offsetTop = form.offset().top - $("#header-wrapper").outerHeight();

                $('html, body').animate({scrollTop: offsetTop}, 500);
            }
        }).fail(function(e){
            form.find(".submit-fail").html(arsluna.msg.error.sendMessageFail + "<br/>" + e);
            $("#message-sent-fail").removeClass("display-none");
        });

    } // END submitReservation

    return {
        submitReservation : submitReservation
    }
}(jQuery); // END optikaSimenc
